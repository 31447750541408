import dayjs from 'dayjs';

import {
  CompaniesStockPerformanceAccount,
  EndValuesAccount,
  PerformanceAccount,
  SuperhumanQuestion,
  ThirdPartyServiceItem
} from '@shared/models/Home';

export function findCurrentQuestionID(data: SuperhumanQuestion[]) {
  const filterUnanswered = data.filter((item) => !item || !item.survey_customer_answer);
  if (filterUnanswered && filterUnanswered.length) {
    return filterUnanswered[0].id;
  }
  return data[data.length - 1].id;
}

export type SingleAccountType = PerformanceAccount | EndValuesAccount | CompaniesStockPerformanceAccount;
export type MultipleAccountType = PerformanceAccount[] | EndValuesAccount[] | CompaniesStockPerformanceAccount[];

export function addOrUpdateAccount(item: SingleAccountType, accounts: MultipleAccountType): MultipleAccountType {
  const accountsCopy = [...accounts];
  const findIndex = accountsCopy.findIndex((account) => item.account.id === account.account.id);
  if (findIndex >= 0) {
    accountsCopy[findIndex] = item;
  } else {
    accountsCopy.push(item);
  }
  return accountsCopy as MultipleAccountType;
}

export function dateDiffInDays(a: Date, b: Date) {
  const MS_PER_DAY = 86400000;
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  return Math.floor((utc2 - utc1) / MS_PER_DAY);
}

export function mapTechnicalIssues(items: ThirdPartyServiceItem[]) {
  const technicalIssues = {
    de3b: false,
    at3b: false,
    ch3b: false,
    ch3a: false,
    chkid: false
  };
  items.forEach((item) => {
    switch (item.attributes.feature_key) {
      case 'reporting_ch_3b':
        technicalIssues.ch3b = !item.attributes.value;
        technicalIssues.chkid = !item.attributes.value;
        break;
      case 'reporting_de_3b':
        technicalIssues.de3b = !item.attributes.value;
        technicalIssues.at3b = !item.attributes.value;
        break;
      case 'reporting_ch_3a':
        technicalIssues.ch3a = !item.attributes.value;
        break;
      default:
        break;
    }
  });
  return technicalIssues;
}

export function getPercentageByDate(start: string, end: string): number {
  const startDate = dayjs(start);
  const endDate = dayjs(end);
  const totalTime = endDate.diff(startDate, 'hours');
  const progress = endDate.diff(dayjs(), 'hours');
  const percentage = (progress / totalTime) * 100;
  return percentage;
}

export function getChosenRiskLevel(split: number) {
  switch (true) {
    case split > 0.8:
      return 'very_dynamic';
    case split > 0.6:
      return 'dynamic';
    case split > 0.4:
      return 'balanced';
    case split > 0.2:
      return 'conservative';
    case split >= 0:
      return 'very_conservative';
    default:
      return 'balanced';
  }
}

export function formatDate(date: string, seperator: '.' | '-', lang: string) {
  if (!date) {
    return '';
  }

  let finalDate = date;

  if (seperator === '.') {
    const dateSplited = date.split(seperator);
    finalDate = `${dateSplited[1]}/${dateSplited[0]}/${dateSplited[2]}`;
  }

  return new Date(finalDate).toLocaleString(lang, {
    year: 'numeric',
    day: 'numeric',
    month: 'short'
  });
}
