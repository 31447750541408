import { CustomerAccountItem } from '@inyova/models';

export interface PerformanceItem {
  date: string;
  benchmark_return: number;
  return: number;
  end_value: number;
  transactions: PerformanceTransactionItem[];
}

export interface PerformanceAccount {
  account: CustomerAccountItem;
  graphData: PerformanceItem[];
}

export enum PerformanceFilterRange {
  WEEK = '1W',
  MONTH = '1M',
  HALF_YEAR = '6M',
  YEAR = '1Y',
  ALL = '',
  CURRENT_YEAR = 'CY'
}

export enum PerformanceType {
  BALANCE = 'balance',
  PERFORMANCE = 'performance'
}

export interface PerformanceData {
  graphData: PerformanceItem[];
  loading: boolean;
  filter: {
    range: PerformanceFilterRange;
    chartType: PerformanceType;
  };
}

export interface PerformanceGraphItem {
  date: Date;
  value: number;
  benchmarkValue?: number;
  endValue?: number;
  transactions: PerformanceTransactionItem[];
}

export interface PerformanceChart {
  loading: boolean;
  range: PerformanceFilterRange;
  chartType: PerformanceType;
  firstEndValue: number;
}

export interface EndValuesAccount {
  account: CustomerAccountItem;
  endValues: ReportEndValues;
  hasError: boolean;
  initialised: boolean;
}

export interface CompaniesStockPerformanceAccount {
  account: CustomerAccountItem;
  companies: CompaniesStockPerformance[];
  hasError: boolean;
  initialised: boolean;
}

export interface PerformanceTransactionItem {
  transaction_type: string;
  transaction_value: number;
}

export interface ReportEndValues {
  benchmark_return: number;
  end_date: string;
  deposits_value: number;
  first_date: string;
  end_value: number;
  fee_all: number;
  first_month: string;
  first_year: number;
  highest_return_net: number;
  last_benchmark_date: string;
  lowest_return_net: number;
  money_promotions: number;
  monthly_investment: number;
  profit_all: number;
  reimbursement_all: number;
  return_all_gross: number;
  return_all_net: number;
  simple_return_all_gross: number;
  simple_return_all_net: number;
  start_value: number;
  total_investment: number;
  withdrawal_all: number;
}

export interface ImpactStoriesItem {
  id: number;
  type: string;
  attributes: {
    engagement_campaign: boolean;
    liked?: boolean;
    image: string;
    publish_date: string;
    title: string;
    big_image: boolean;
    expires_at: string;
    expired?: boolean;
    supporters_number?: number;
    inyova_id?: number;
    remaining?: {
      hours: number;
      days: number;
      minutes: number;
      totalDays: number;
      totalHours: number;
    };
    // it's required for publicis campaign
    isPublicis?: boolean;
  };
}

export interface ImpactStoryDetail {
  id: number;
  type: string;
  attributes: {
    title: string;
    subtitle: string;
    publish_date: string;
    image: string;
    big_image: boolean;
    engagement_campaign: boolean;
    content: string;
    cta: boolean;
    expires_at: string;
    linked?: boolean;
    link: string;
    link_title: string;
    company_image: string;
    shareholder?: boolean;
    liked?: boolean;
    supporters_number?: number;
    expired?: boolean;
  };
}

export interface CompaniesStockPerformance {
  id: string;
  type: string;
  attributes: {
    company_id: number;
    image: string;
    performance: number;
    title: string;
    position_value: number;
    number_of_shares: number;
  };
}

export interface ImpactMetric {
  id: string;
  type: string;
  attributes: ImpactMetricItem;
}
export interface ImpactMetricItem {
  name: string;
  value: number;
  units: string;
  comparison_value: number;
}

export interface EngagementDetail {
  liked: boolean;
  impact_story_id: number;
  customer_id: number;
}

export interface ThirdPartyServiceItem {
  id: string;
  type: string;
  attributes: {
    feature_key: string;
    value: boolean;
  };
}
export interface CrowdInvestingValues {
  number_of_shares: number;
  total_stock_value: number;
  stock_value: number;
}

export interface SuperhumanQuestion {
  id: number;
  question_text: string;
  type: string;
  subtype?: string;
  hint?: string;
  inyova_id?: number;
  survey_answer_options?: SuperhumanQuestionOption[];
  survey_customer_answer: SuperhumanAnswer;
}

export interface SuperhumanQuestionOption {
  id: number;
  option_text: string;
}

export interface SuperhumanAnswer {
  id: number;
  answer: number | string | [];
}

export interface SuperhumanPutAnswer {
  question_id: number;
  answer: number | string | [];
}

export interface MobileReportsModel {
  customer: {
    reporting: PerformanceItem[];
  };
}

export interface TechnicalIssuesReporting {
  de3b: boolean;
  at3b: boolean;
  ch3b: boolean;
  ch3a: boolean;
  chkid: boolean;
}

export interface PerformanceSummary {
  end_period: string;
  return_net: number;
  return_gross: number;
  return_gross_benchmark: number;
  net_simple_return: number;
  gross_simple_return: number;
}

export interface BalanceSheet {
  account_balance: number;
  total_deposit: number;
  total_withdrawal: number;
  amount_in_market: number;
  amount_in_cash: number;
  net_investment: number;
  stocks_amount: number;
  bonds_amount: number;
  total_earned: number;
  from_market_change: number;
  from_promotion: number;
  total_fees_paid: number;
  inyova_mgmt_fees: number;
  custodian_fees: number;
  foundation_fees: number;
  reimbursements: number;
  updated_at: string;
}

export interface PromoCampaign {
  data: PromoCampaignItem[];
  included: PromoItem[];
}

export interface PromoCampaignItem {
  id: string;
  type: string;
  attributes: {
    name: string;
    description: string;
    image: string;
    start_date: string;
    end_date: string;
    opted_in: null | boolean;
    tnc_link: string;
  };
  promotions: PromoItem[];
  relationships: {
    deposit_promotions: {
      data: [
        {
          type: string;
          id: string;
        }
      ];
      links: {
        related: string;
      };
    };
  };
}

export interface PromoItem {
  id: string;
  type: string;
  attributes: {
    promotion_campaign_id: number;
    value: number;
    deposit_target: number;
    months_distribution_no: number;
  };
}

export interface PromoParticipation {
  id: number;
  type: string;
  attributes: {
    opted_in: boolean;
    promotion_campaign_id: number;
  };
}
