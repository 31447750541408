import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { Injectable } from '@angular/core';

import { Customer } from '../../../models';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {
  init(amplitudeApiKey: string, clientType: 'web' | 'app') {
    const identifyEvent = new amplitude.Identify();
    identifyEvent.set('client_type', clientType);

    amplitude.identify(identifyEvent);

    amplitude.add(sessionReplayPlugin());

    amplitude.init(amplitudeApiKey, {
      autocapture: {
        elementInteractions: true
      }
    });
  }

  reset() {
    amplitude.reset();
  }

  setUserProperties(customer: Customer) {
    const identifyEvent = new amplitude.Identify();
    identifyEvent.set('displayed_country', customer.app_location);
    identifyEvent.set('displayed_language', customer.language);
    identifyEvent.set('created_at', new Date(customer.created_at).toISOString());
    identifyEvent.set('is_test', customer.is_test);

    amplitude.setUserId(customer.track_id);
    amplitude.identify(identifyEvent);
  }

  setDisplayedLanguage(language: string) {
    const identifyEvent = new amplitude.Identify();
    identifyEvent.set('displayed_language', language);

    amplitude.identify(identifyEvent);
  }

  trackEvent(eventName: string, eventProps?: any) {
    amplitude.track(eventName, eventProps);
  }
}
