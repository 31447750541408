import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { FingerprintAIO } from '@awesome-cordova-plugins/fingerprint-aio/ngx';
import { AmplitudeService, FeatureFlagService, InyovaConfigService } from '@inyova/inyova-shared';
import { Customer, CustomerAccountItem } from '@inyova/models';
import { AlertController, LoadingController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';

import { Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import * as AccountActions from '@account/account.actions';
import * as GrowActions from '@grow/inyova-grow.actions';
import * as HomeActions from '@home/home.actions';

import { IonicStorageKeys, Languages, LocalStorageKeys } from '@app/app.constants';
import { LoginType } from '@public/public.constants';
import { State } from '@shared/models/State';
import { environment } from 'src/environments/environment';

import { LanguageService } from './language.service';
import { ToastService } from './toast.service';
import { TokenService } from './token.service';
import { DeviceResources } from '../yova-api/device.resources';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authenticationState = new BehaviorSubject<CustomerAccountItem>({} as CustomerAccountItem);

  constructor(
    private alertController: AlertController,
    private device: Device,
    private deviceResources: DeviceResources,
    private http: HttpClient,
    private faio: FingerprintAIO,
    private languageService: LanguageService,
    private loadingController: LoadingController,
    private platform: Platform,
    private storage: Storage,
    private store: Store<State>,
    private toastService: ToastService,
    private tokenService: TokenService,
    private translateService: TranslateService,
    private inyovaConfigService: InyovaConfigService,
    private amplitudeService: AmplitudeService,
    private featureFlagService: FeatureFlagService
  ) {
    void this.platform.ready().then(() => {
      if (
        (this.platform.is('mobileweb') || this.platform.is('desktop')) &&
        window.location.pathname !== '/public/login' &&
        !this.device?.uuid
      ) {
        this.checkToken(true);
      }
    });
  }

  checkToken(showLoader = false) {
    if (localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN)) {
      void this.validateToken(showLoader);
    }
  }

  getLastAuthToken(): Observable<string> {
    return new Observable((observer) => {
      if (localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN)) {
        this.tokenService.validateToken().subscribe((res) => {
          localStorage.setItem(LocalStorageKeys.AUTHENTICATION_TOKEN, res.data.authentication_token);
          observer.next(res.data.authentication_token);
        });
      } else {
        observer.next(null);
      }
    });
  }

  getProvider(email: string): Observable<{ provider: LoginType }> {
    return this.http.get<{ provider: LoginType }>(`${environment.apiUrl}/mobile/customer?email=${email.toLowerCase()}`);
  }

  isAuthenticated() {
    return Object.keys(this.authenticationState.value).length;
  }

  async login(customer: Customer) {
    void this.storage.set(IonicStorageKeys.LOGIN_TYPE, customer.provider);
    void this.storage.set(IonicStorageKeys.EMAIL, customer.email);
    void this.storage.set(IonicStorageKeys.FIRST_NAME, customer.first_name);
    void this.storage.set(IonicStorageKeys.LANGUAGE, customer.language);
    void this.languageService.setLanguage(customer.language as Languages);
    await firstValueFrom(this.featureFlagService.initialize());

    // Set user context to get to know which user has the issue, if customer obj exists
    Sentry.setUser({
      id: `${customer.inyova_id}`
    });
    this.amplitudeService.setUserProperties(customer);

    this.store.dispatch(AccountActions.getABTests());

    // Initialise main performance data
    if (customer.accounts && customer.accounts.length) {
      customer.accounts.forEach((item) => {
        if (item.step_status === 5) {
          this.store.dispatch(HomeActions.getEndValues({ account: item }));
          this.store.dispatch(HomeActions.getGraphData({ account: item }));
        }
      });
    }
    this.store.dispatch(HomeActions.getAllReportingsStatus());

    if (customer.has_interest_account) {
      // Initialise Grow acc data
      this.store.dispatch(GrowActions.getInyovaGrowAccount());
    }
    if (customer.has_crowd_investor_account) {
      // Initialise crowdinvestor data
      this.store.dispatch(HomeActions.getCrowdInvestingData());
    }

    const authState = { ...this.selectCurrentAccount(customer.accounts), isCrowdinvestor: customer.has_crowd_investor_account };
    return this.authenticationState.next(authState);
  }

  loginGoogle(idToken: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http.post<any>(`${environment.apiUrl}/mobile/callback`, { token: idToken }).subscribe(
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        async (res) => {
          localStorage.setItem('accessToken', res.auth_token);
          localStorage.setItem('client', res.client_id);
          localStorage.setItem('expiry', res.expiry);
          localStorage.setItem('tokenType', 'Bearer');
          localStorage.setItem('uid', res.uid);
          await this.validateToken();
          resolve();
        },
        (err) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const errorCode: string = err?.error?.code || '';
          if (errorCode.includes('vp_account')) {
            void this.toastService.errorWithClose(this.translateService.instant('SHARED.errors.vpAccount') as string, 0);
          }
          reject(err);
        }
      );
    });
  }

  logout() {
    this.amplitudeService.reset();
    this.inyovaConfigService.setAccountId(null);
    return this.authenticationState.next({} as CustomerAccountItem);
  }

  setupBiometric() {
    return this.faio.show({
      title: this.translateService.instant('SHARED.biometricLogin.androidTitle'), // Title in biometric prompt (android only)
      subtitle: this.translateService.instant('SHARED.biometricLogin.title'), // Subtitle in biometric Prompt (android only)
      description: this.translateService.instant('SHARED.biometricLogin.allowQuestion'), // Description in biometric Prompt
      fallbackButtonTitle: this.translateService.instant('SHARED.biometricLogin.fallback'), // Title of fallback button.
      cancelButtonTitle: this.translateService.instant('SHARED.actions.no'), // Title for cancel button on Android
      disableBackup: this.platform.is('android') // Disable 'use backup' for Android devices (Android 10 issue)
    });
  }

  selectCurrentAccount(accounts: CustomerAccountItem[]): CustomerAccountItem {
    let mainAccount = {} as CustomerAccountItem;
    if (!accounts || !accounts.length) {
      return mainAccount;
    }
    // Deep links related variables
    const redirectURL = localStorage.getItem(LocalStorageKeys.REDIRECT_AFTER_LOGOUT);
    const preferedAccount = redirectURL && redirectURL.includes('?account=') ? redirectURL.split('?account=')[1] : null;

    // Last saved account ID in local storage
    const currentAccountID = localStorage.getItem(LocalStorageKeys.CURRENT_ACCOUNT_ID);

    if (accounts.length === 1) {
      mainAccount = accounts[0];
    } else if (preferedAccount) {
      const kidAccountQuery = redirectURL.includes('?id=') ? redirectURL.split('?id=')[1] : null;
      if (preferedAccount === 'kid' && kidAccountQuery) {
        const kidId = kidAccountQuery
          .split('&')
          .find((el) => el.includes('id='))
          .substring(3);
        mainAccount = {
          ...accounts.filter((account: CustomerAccountItem) => account.id === kidId)[0]
        };
      } else {
        mainAccount = {
          ...accounts.filter((account: CustomerAccountItem) => account.kind === preferedAccount && account.step_status === 5)[0]
        };
      }
    } else if (currentAccountID) {
      mainAccount = {
        ...accounts.filter((account: CustomerAccountItem) => account.id === currentAccountID && account.step_status === 5)[0]
      };
    }
    if (!mainAccount.id) {
      // we used opposite order because reduce method finds the last item in the array.
      // The array will be ordered like ['kid','kid','3a','3b']
      const accountOrder = ['kid', '3a', '3b'];
      const orderedAccounts = [...accounts];
      orderedAccounts.sort((a, b) => accountOrder.indexOf(a.kind) - accountOrder.indexOf(b.kind));

      // find step 5 last account according to the this rule 3b > 3a > kid
      mainAccount = orderedAccounts.reduce((firstAccount: CustomerAccountItem, secondAccount: CustomerAccountItem) =>
        firstAccount.step_status > secondAccount.step_status ? firstAccount : secondAccount
      );
    }
    this.store.dispatch(AccountActions.setCurrentAccountID({ id: mainAccount.id }));
    this.store.dispatch(AccountActions.getCurrentAccount({ id: mainAccount.id }));
    return mainAccount;
  }

  validateBiometric(deviceUuid: string, biometricToken: string) {
    this.faio
      .show({
        title: this.translateService.instant('SHARED.biometricLogin.androidTitle'), // Title in biometric prompt (android only)
        subtitle: this.translateService.instant('SHARED.biometricLogin.title'), // Subtitle in biometric Prompt (android only)
        description: this.translateService.instant('SHARED.biometricLogin.allowQuestion'), // Description in biometric Prompt
        fallbackButtonTitle: this.translateService.instant('SHARED.biometricLogin.fallback'), // Title of fallback button.
        cancelButtonTitle: this.translateService.instant('SHARED.actions.no'), // Title for cancel button on Android
        disableBackup: this.platform.is('android') // Disable 'use backup' for Android devices (Android 10 issue)
      })
      .then(async () => {
        const loading = await this.loadingController.create({});
        await loading.present();
        this.store.dispatch(AccountActions.validateDevice({ deviceUuid, biometricToken }));
      })
      .catch(() => {
        // Do nothing
      });
  }

  async removeCustomerNotifications(deviceUuid: string) {
    const loading = await this.loadingController.create({});
    await loading.present();
    this.deviceResources.removeDeviceNotifications(deviceUuid).subscribe(
      () => {
        this.loadingController.dismiss();
        this.storage.clear();
      },
      () => {
        this.loadingController.dismiss();
        this.storage.clear();
      }
    );
  }

  private async validateToken(showLoader = false) {
    // Add loading for validation token
    if (showLoader) {
      const loading = await this.loadingController.create({});
      await loading.present();
    }
    // Validate session
    this.tokenService.validateToken().subscribe(
      (res) => {
        localStorage.setItem(LocalStorageKeys.AUTHENTICATION_TOKEN, res.data.authentication_token);
        this.store.dispatch(AccountActions.setCustomer({ data: res.data }));
        this.store.dispatch(AccountActions.getCustomer({ doLogin: false }));
        this.login(res.data);
        if (showLoader) {
          void this.loadingController.dismiss();
        }
      },
      () => {
        this.storage.remove(IonicStorageKeys.HIDE_ODD_MODAL);

        this.store.dispatch(AccountActions.customerLogout());
        if (showLoader) {
          void this.loadingController.dismiss();
        }
      }
    );
  }

  async checkAuthPreferencies() {
    const alert = await this.alertController.create({
      cssClass: 'ion-alert--custom',
      header: this.translateService.instant('SHARED.biometricLogin.title'),
      message: this.translateService.instant('SHARED.biometricLogin.allowQuestion'),
      buttons: [
        {
          text: this.translateService.instant('SHARED.actions.no'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => this.storage.set(IonicStorageKeys.BIOMETRIC_LOGIN_PREF, false)
        },
        {
          text: this.translateService.instant('SHARED.actions.yes'),
          handler: () =>
            this.setupBiometric()
              .then(() => {
                void this.storage.set(IonicStorageKeys.BIOMETRIC_LOGIN_PREF, true);
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                void this.toastService.show(this.translateService.instant('SHARED.biometricLogin.flash.biometricSuccess'));
                this.store.dispatch(AccountActions.addNewDevice({ deviceUuid: this.device.uuid }));
              })
              .catch(() => {
                void this.storage.set(IonicStorageKeys.BIOMETRIC_LOGIN_PREF, false);
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                void this.toastService.error(this.translateService.instant('SHARED.biometricLogin.flash.biometricFailed'));
              })
        }
      ]
    });
    await alert.present();
  }
}
