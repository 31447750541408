import { Bond } from '@strategy/components/strategy-portfolio-companies/strategy-portfolio-companies.component';

// EU Countries - https://ec.europa.eu/eurostat/statistics-explained/index.php/Glossary:Country_codes
export const EUROPE_COUNTRIES = [
  'de',
  'be',
  'lt',
  'pt',
  'bg',
  'es',
  'lu',
  'ro',
  'cz',
  'fr',
  'hu',
  'si',
  'dk',
  'hr',
  'mt',
  'sk',
  'de',
  'it',
  'nl',
  'fi',
  'ee',
  'cy',
  'at',
  'se',
  'ie',
  'lv',
  'pl',
  'gb',
  'mc',
  'ad',
  'ch',
  'fo',
  'gi',
  'ru',
  'rs',
  'tr',
  'ua',
  'am',
  'by',
  'ba',
  'ge',
  'mk',
  'md',
  'al',
  'me',
  'gr',
  'is',
  'li',
  'no'
];

export const ACCESS_TOKEN = 'accessToken';
export const BIOMETRIC_LOGIN_KEY = 'biometricLogin';

export enum IonicStorageKeys {
  BIOMETRIC_LOGIN_PREF = 'biometricLoginPref',
  BIOMETRIC_TOKEN = 'biometricToken',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LANGUAGE = 'language',
  LOGIN_TYPE = 'loginType',
  PUSH_NOTIFICATIONS_PREF = 'pushNotificationsPref',
  REMOTE_CONFIG_NEXT_FETCH = 'remoteConfigNextFetch',
  REMOTE_CONFIG_LAST_VALUE = 'remoteConfigLastValue',
  HIDE_ODD_MODAL = 'hideOddModal'
}

export enum LocalStorageKeys {
  ACCESS_TOKEN = 'accessToken',
  APP_PAUSED = 'appPaused',
  AUTHENTICATION_TOKEN = 'authenticationToken',
  CHANGE_PHONE_NUMBER_REQUEST = 'changePhoneNumberRequest',
  CURRENT_ACCOUNT_ID = 'currentAccountID',
  PHONE_NUMBER = 'phoneNumber',
  REDIRECT_AFTER_LOGOUT = 'redirectAfterLogin',
  TRACK_PUSH_NOTIFICATION = 'trackPushNotification'
}

export enum Documents3APagination {
  DEFAULT_RANGE = 20,
  LOAD_NEW = 10
}

export enum Languages {
  DE_DE = 'de-DE',
  DE_CH = 'de-CH',
  EN_DE = 'en-DE',
  EN_CH = 'en-CH',
  FR_CH = 'fr-CH',
  DE_AT = 'de-AT',
  EN_AT = 'en-AT'
}

export enum ABTests {
  FAKE_DOOR = 'fake_door'
}

export const TWO_FA_FAQ_ARTICLE = {
  'de-CH': 'https://help.inyova.ch/hc/de/articles/360017270259-Was-ist-Zwei-Faktor-Authentifizierung-',
  'en-CH': 'https://help.inyova.ch/hc/en-us/articles/360017270259-What-is-two-factor-authentication-',
  'fr-CH': 'https://help.inyova.ch/hc/fr/articles/360017270259-Qu-est-ce-que-l-authentification-%C3%A0-deux-facteurs-',
  'de-DE': 'https://help.inyova.de/hc/de/articles/360017270259-Was-ist-Zwei-Faktor-Authentifizierung-',
  'en-DE': 'https://help.inyova.de/hc/en-us/articles/360017270259-What-is-two-factor-authentication-',
  'de-AT': 'https://help.inyova.de/hc/de/articles/360017270259-Was-ist-Zwei-Faktor-Authentifizierung-',
  'en-AT': 'https://help.inyova.de/hc/en-us/articles/360017270259-What-is-two-factor-authentication-'
};

export const ENABLE_TWO_FA_FAQ_ARTICLE = {
  'de-CH': 'https://help.inyova.ch/hc/de/articles/360017220140-Wie-aktiviere-ich-die-Zwei-Faktor-Authentifizierung-',
  'en-CH': 'https://help.inyova.ch/hc/en-us/articles/360017220140-How-do-I-enable-two-factor-authentication-',
  'fr-CH': 'https://help.inyova.ch/hc/fr/articles/360017220140-Comment-puis-je-activer-l-authentification-%C3%A0-deux-facteurs-',
  'de-DE': 'https://help.inyova.de/hc/de/articles/360017220140-Wie-aktiviere-ich-die-Zwei-Faktor-Authentifizierung-',
  'en-DE': 'https://help.inyova.de/hc/en-us/articles/360017220140-How-do-I-enable-two-factor-authentication-',
  'de-AT': 'https://help.inyova.de/hc/de/articles/360017220140-Wie-aktiviere-ich-die-Zwei-Faktor-Authentifizierung-',
  'en-AT': 'https://help.inyova.de/hc/en-us/articles/360017220140-How-do-I-enable-two-factor-authentication-'
};

// https://www.amundietf.de/de/privatanleger/products/fixed-income/lyxor-green-bond-dr-ucits-etf-monthly-hedged-to-eur-acc/lu1563454823
export const BONDS_LIST: Bond[] = [
  {
    id: 'id4',
    img: '/assets/images/bond-flags/germany.svg',
    perc: 17.29
  },
  {
    id: 'id3',
    img: '/assets/images/bond-flags/france.svg',
    perc: 13.26
  },
  {
    id: 'id9',
    img: '/assets/images/bond-flags/netherlands.svg',
    perc: 12.21
  },
  {
    id: 'id10',
    img: '/assets/images/bond-flags/other.svg',
    perc: 10.25
  },
  {
    id: 'id1',
    img: '/assets/images/bond-flags/usa.svg',
    perc: 8.65
  },
  {
    id: 'id6',
    img: '/assets/images/bond-flags/italy.svg',
    perc: 5.75
  },
  {
    id: 'id7',
    img: '/assets/images/bond-flags/spain.svg',
    perc: 4.37
  },
  {
    id: 'id8',
    img: '/assets/images/bond-flags/other.svg',
    perc: 28.22
  }
];

export const ESTIMATE_CONSTANTS_DE = {
  confidenceInterval: 1.44,
  stocksReturn: 0.0812,
  bondsReturn: 0.011,
  stocksVol: 0.1817,
  bondsVol: 0.043,
  correlation: 0.0203
};

export const ESTIMATE_CONSTANTS_CH = {
  confidenceInterval: 1.65,
  stocksReturn: 0.0787,
  bondsReturn: 0.011,
  stocksVol: 0.1651,
  bondsVol: 0.043,
  correlation: 0.0244
};

// https://www.ubs.com/ch/en/private/pension/pillar-3/maximal-contribution-2024.html
export const MAXIMUM_3A_CONTRIBUTION = {
  2024: {
    small: 7056,
    large: 35280,
    transfer_only: 0
  }
};

export const RETIREMENT_AGE = 65;

// Commented is Lokalise translation
export const COMPANY_SECTOR_API_KEY = {
  Energy: 1, // Energy
  Materials: 2, // Raw materials
  Industrials: 3, // Industrials
  'Consumer Discretionary': 4, // Discretionary goods
  'Consumer Staples': 5, // Consumer staple goods
  'Health Care': 6, // Health care
  Financials: 7, // Financials
  'Information Technology': 8, // Information technology
  'Communication Services': 9, // Communication services
  Utilities: 10, // Utilities
  'Real Estate': 11 // Real estate
};

export const EXTERNAL_URLS = {
  pillar3a: 'home?openExternal=new-account'
};

export const IMPACT_CAMPAIGNS_INYOVA_IDS = [1, '1'];
export const IMPACT_CAMPAIGNS_UPDATE_INYOVA_IDS = [2, '2'];

export const DEFAULT_HOURS = '00,01,02,03,04,05,06,07,08,09,10,11,12,13,14,15,16,17,18,19,20,21,22,23';
