import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Jsona } from 'jsona';
import { TJsonApiBody } from 'jsona/lib/JsonaTypes';

import { map, Observable, Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';

import { InyovaConfigService } from './inyova-config.service';
import { FeatureFlag } from '../../../models';

@Injectable({ providedIn: 'root' })
export class FeatureFlagService implements OnDestroy {
  private initialize$: Observable<void> | null = null;
  protected readonly onDestroy$ = new Subject<void>();

  private jsona = new Jsona();
  private _featureFlags: Map<string, boolean> = new Map();

  private urlV3: string;

  constructor(
    private http: HttpClient,
    private sharedService: InyovaConfigService
  ) {
    this.sharedService
      .getApiData()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(({ urlV3 }) => {
        this.urlV3 = urlV3;
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initialize(): Observable<void> {
    if (!this.initialize$) {
      this.initialize$ = this.getFeatureFlags().pipe(
        map((featureFlags) => {
          this._featureFlags = new Map(featureFlags.map(({ feature_key, value }) => [feature_key, value]));
        }),
        shareReplay(1)
      );
    }
    return this.initialize$;
  }

  getFeatureFlags(): Observable<FeatureFlag[]> {
    return this.http
      .get<TJsonApiBody>(`${this.urlV3}/flipper_gates`)
      .pipe(map((response) => this.jsona.deserialize({ ...response }) as FeatureFlag[]));
  }

  featureDisabled(featureName: string) {
    return !this.featureEnabled(featureName);
  }

  featureEnabled(featureName: string): boolean {
    return this._featureFlags.get(featureName) ?? false;
  }
}
