import { createAction, props } from '@ngrx/store';

import {
  InyovaGrowAccount,
  InyovaGrowPercentages,
  InyovaGrowRisk,
  InyovaGrowSurveyQuestion,
  InyovaGrowSubmit,
  InyovaGrowSubmitResponse,
  GrowPortfolioAllocation,
  GrowProjectsResponse,
  GrowProjectInvestment
} from '@app/shared/models/Grow';

export const createInyovaGrowAccount = createAction('[Grow]  create inyova grow account');
export const createInyovaGrowAccountSuccess = createAction('[Grow]  create inyova grow account success', props<{ data: InyovaGrowAccount }>());
export const createInyovaGrowAccountFail = createAction('[Grow]  create inyova grow account fail');

export const getInyovaGrowAccount = createAction('[Grow] Get the grow account details');
export const getInyovaGrowAccountSuccess = createAction('[Grow] Get the grow account details success', props<{ data: InyovaGrowAccount }>());
export const getInyovaGrowAccountFail = createAction('[Grow] Get the grow account details fail');

export const getGrowIban = createAction('[Grow] Check Inyova grow IBAN');
export const getGrowIbanSuccess = createAction('[Grow] Check Inyova grow IBAN success', props<{ iban: string }>());
export const getGrowIbanFail = createAction('[Grow] Check Inyova grow IBAN fail');

export const getGrowProjects = createAction('[Grow] Get Grow projects');
export const getGrowProjectsSuccess = createAction('[Grow] Get Grow projects success', props<GrowProjectsResponse>());
export const getGrowProjectsFail = createAction('[Grow] Get Grow projects fail');

export const getGrowPortfolioAllocationSuccess = createAction(
  '[Grow] Get Grow portfolio allocation success',
  props<{ growPortfolioAllocation: GrowPortfolioAllocation }>()
);
export const getGrowPortfolioAllocationFail = createAction('[Grow] Get Grow portfolio allocation fail');

export const markGrowProject = createAction('[Grow] Mark Grow project', props<{ projectId: string; openInvestmentModal: boolean }>());
export const markGrowProjectSuccess = createAction(
  '[Grow] Mark Grow project success',
  props<{ projectId: string; investment: GrowProjectInvestment; openInvestmentModal: boolean }>()
);
export const markGrowProjectFail = createAction('[Grow] Mark Grow project fail', props<{ error: any }>());

export const unmarkGrowProject = createAction('[Grow] Unmark Grow project', props<{ projectId: string }>());
export const unmarkGrowProjectSuccess = createAction('[Grow] Unmark Grow project success', props<{ projectId: string }>());
export const unmarkGrowProjectFail = createAction('[Grow] Unmark Grow project fail', props<{ error: any }>());

export const openInvestmentModal = createAction('[Grow] Open Investment Modal', props<{ projectId: string }>());

export const getGrowRisk = createAction('[Grow] Get Inyova Grow risk');
export const getGrowRiskSuccess = createAction(
  '[Grow] Get Inyova Grow risk success',
  props<{ data: InyovaGrowRisk; percentages: InyovaGrowPercentages }>()
);
export const getGrowRiskFail = createAction('[Grow] Get Inyova Grow risk fail');

export const checkGrowRiskProfile = createAction(
  '[Grow] Check Inyova grow risk profile',
  props<{ answers: InyovaGrowSurveyQuestion[]; availableAssets: number; initInvest: number }>()
);
export const checkGrowRiskProfileSuccess = createAction('[Grow] Check Inyova grow risk profile success', props<{ data: InyovaGrowPercentages }>());
export const checkGrowRiskProfileFail = createAction('[Grow] Check Inyova grow risk profile fail');

export const updateInyovaGrowRisk = createAction('[Grow] Update Inyova Grow risk', props<{ data: InyovaGrowSubmit; nextPage: string }>());
export const updateInyovaGrowRiskSuccess = createAction(
  '[Grow] Update Inyova Grow risk success',
  props<{ data: InyovaGrowSubmitResponse; nextPage: string }>()
);
export const updateInyovaGrowRiskFail = createAction('[Grow] Update Inyova Grow risk fail');

export const finishGrowProjectInvestment = createAction('[Grow] Finish Investment', props<{ projectId: string; amount: number }>());
export const finishGrowProjectInvestmentSuccess = createAction('[Grow] Finish Investment Success', props<{ projectId: string; amount: number }>());
export const finishGrowProjectInvestmentReached = createAction('[Grow] Finish Investment Reached');
export const finishGrowProjectInvestmentFail = createAction('[Grow] Finish Investment Fail');
export const finishGrowProjectInvestmentReset = createAction('[Grow] Finish Investment Reset');
