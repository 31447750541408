import { Component, Input, OnInit } from '@angular/core';
import { getRemainingTime } from '@inyova/inyova-shared';

import { getPercentageByDate } from '@app/home/home.utils';

@Component({
  selector: 'app-remaining-time-label',
  templateUrl: './remaining-time-label.component.html',
  styleUrls: ['./remaining-time-label.component.scss']
})
export class RemainingTimeLabelComponent implements OnInit {
  @Input() start: string;
  @Input() end: string;
  remainingTime: {
    years: number;
    months: number;
    days: number;
    hours: number;
    minutes: number;
    totalDays: number;
  };

  ngOnInit(): void {
    this.remainingTime = getRemainingTime(this.end);
  }

  showRemainingTimeLabel(): string {
    if (this.remainingTime.months > 1) return 'PROMO.multipleMonthsLeft';
    if (this.remainingTime.months === 1 && this.remainingTime.days >= (30 || 31)) return 'PROMO.singleMonthLeft';
    if (this.remainingTime.months < 1 && this.remainingTime.days > 1) return 'PROMO.multipleDaysLeft';
    if (this.remainingTime.days === 1 && this.remainingTime.hours >= 24) return 'PROMO.singleDayLeft';
    if (this.remainingTime.days < 1 && this.remainingTime.hours > 1) return 'PROMO.multipleHoursLeft';
    if (this.remainingTime.hours === 1) 'PROMO.singleHourLeft';
    return 'PROMO.lessThanHourLeft';
  }

  getColor(): string {
    const percentage = getPercentageByDate(this.start, this.end);
    if (percentage > 66) return 'success';
    if (percentage > 33) return 'info';
    return 'warning';
  }
}
