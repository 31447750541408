import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { getRemainingTime } from '@inyova/inyova-shared';

@Component({
  selector: 'app-home-odd-modal',
  templateUrl: './home-odd-modal.component.html',
  styleUrls: ['./home-odd-modal.component.scss']
})
export class HomeOddModalComponent implements OnInit {
  @Input() oddDate!: string;
  @Input() language: string;
  @Input() name: string;
  @Output() review: EventEmitter<void> = new EventEmitter<void>();
  @Output() doItLater: EventEmitter<void> = new EventEmitter<void>();

  days: number;
  date: string;

  ngOnInit() {
    this.days = getRemainingTime(this.oddDate).days;
    this.date = new Date(this.oddDate).toLocaleString(this.language, {
      year: 'numeric',
      day: 'numeric',
      month: 'long'
    });
  }

  onReview() {
    this.review.emit();
  }

  onDoItLater() {
    this.doItLater.emit();
  }
}
