import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { FingerprintAIO } from '@awesome-cordova-plugins/fingerprint-aio/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { InyovaConfigModule, InyovaSharedModule } from '@inyova/inyova-shared';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as fromRoot from './app.reducers';

import { HomePageModule } from '@home/home.module';
import { PublicModule } from '@public/public.module';
import { HttpRequestInterceptor } from '@shared/services/http.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Languages } from './app.constants';
import { environment } from '../environments/environment';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    EffectsModule.forRoot(),
    HttpClientModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    HomePageModule,
    PublicModule,
    ReactiveFormsModule,
    InyovaSharedModule,
    InyovaConfigModule.forRoot({ url: environment.apiUrl, urlV3: environment.apiUrlV3 }),
    TranslateModule.forRoot({
      defaultLanguage: Languages.DE_CH,
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot(
      { root: fromRoot.reducer },
      {
        // See: https://ngrx.io/guide/store/configuration/runtime-checks
        runtimeChecks: {
          strictActionImmutability: false,
          strictActionSerializability: false,
          strictStateImmutability: !environment.production,
          strictStateSerializability: false
        }
      }
    ),
    !environment.production ? StoreDevtoolsModule.instrument() : []
  ],
  providers: [
    Device,
    FileOpener,
    FingerprintAIO,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
