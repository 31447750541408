import dayjs from 'dayjs';

export function getRemainingTime(futureDate: string) {
  const now = dayjs();
  const future = dayjs(futureDate);

  const remaining = {
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    totalDays: 0,
    totalHours: 0
  };

  if (future.unix() < now.unix()) {
    return remaining;
  }

  remaining.years = future.diff(now, 'years');
  remaining.months = future.diff(now, 'months');
  remaining.days = future.diff(now, 'days');
  remaining.hours = future.diff(now, 'hours');
  remaining.minutes = future.diff(now, 'minutes');

  remaining['totalDays'] = remaining['days'];
  remaining['totalHours'] = remaining['hours'];

  // if remaining hour > 13 increase total day
  if (remaining.hours >= 13 && remaining.days) {
    remaining.totalDays = remaining.days + 1;
  }

  // if remaining minutes >= 30 increase total hours
  if (remaining.minutes >= 30 && remaining.hours) {
    remaining.totalHours = remaining.hours + 1;
  }

  return remaining;
}
