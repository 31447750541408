import { GrowProjectInvestment, InyovaGrowAccount, GrowProjectStatus, InyovaGrowRiskGroups, InyovaGrowRiskSurveyQuestion } from '@shared/models/Grow';

export const getGrowProjectBadgeDetails = (status: GrowProjectStatus): { type: string; key: string } => {
  switch (status) {
    case GrowProjectStatus.SELECTED:
      return { type: 'info', key: 'selected' };
    case GrowProjectStatus.INTERESTED:
      return { type: 'info', key: 'interested' };
    case GrowProjectStatus.COMMITTED:
      return { type: 'secondary', key: 'committed' };
    case GrowProjectStatus.IN_TRANSIT:
      return { type: 'warning', key: 'processing' };
    case GrowProjectStatus.INVESTED:
      return { type: 'success', key: 'invested' };
    case GrowProjectStatus.REPAID:
      return { type: 'neutral', key: 'repaid' };
    default:
      return { type: 'hidden', key: '' };
  }
};

/**
 * @description Group questions based on type ( types are defined by UI )
 * @param riskQuestions
 * @return grouped riskQuestions
 */
export const groupRiskQuestions = (riskQuestions: InyovaGrowRiskSurveyQuestion[]): InyovaGrowRiskGroups => {
  let optionalList: InyovaGrowRiskSurveyQuestion | undefined;
  let mandatory: InyovaGrowRiskSurveyQuestion | undefined;
  const optional: InyovaGrowRiskSurveyQuestion[] = [];
  const singleChoice: InyovaGrowRiskSurveyQuestion[] = [];
  const multiChoices: InyovaGrowRiskSurveyQuestion[] = [];

  for (const question of riskQuestions) {
    switch (question.inyova_id) {
      case 36:
        mandatory = question;
        break;
      case 37:
        optionalList = question;
        break;
      case 38:
      case 39:
      case 40:
      case 41:
      case 42:
        optional.push(question);
        break;
      case 43:
      case 44:
        singleChoice.push(question);
        break;
      case 45:
        multiChoices.push(question);
        break;
    }
  }

  return {
    mandatory,
    optionalList,
    optional,
    singleChoice,
    multiChoices
  };
};

export const getRiskType = (step: number) => {
  switch (step) {
    case 0:
      return 'interest_only';
    case 1:
      return 'very_conservative';
    case 2:
      return 'conservative';
    case 3:
      return 'balanced';
    case 4:
      return 'dynamic';
    case 5:
      return 'very_dynamic';
  }
};

export const RISK_RATIO_TO_SLIDER_STEP = { 0: 0, 0.2: 1, 0.4: 2, 0.6: 3, 0.8: 4, 1: 5 };
export const RISK_SLIDER_STEP_TO_RATIO = { 0: 0, 1: 0.2, 2: 0.4, 3: 0.6, 4: 0.8, 5: 1 };

export const getPercentageOfPortfolio = (growAccount: InyovaGrowAccount, amountToInvest: number, projectCurrency: string): number => {
  if (growAccount.portfolio_value === 0) return 0;
  const isDifferentCurrency = projectCurrency.toLowerCase() !== growAccount.currency.toLowerCase();

  const amountInPortfolio = isDifferentCurrency ? amountToInvest * growAccount.eur_exchange_rate : amountToInvest;

  return ((amountInPortfolio / growAccount.portfolio_value) * 100) / 100;
};

export const getGroupedInvestments = (investments: GrowProjectInvestment[]): { status: GrowProjectStatus; amount: number }[] => {
  if (!investments || investments.length === 0) return [{ status: GrowProjectStatus.INITIAL, amount: 0 }];

  const groupedInvestments = investments.reduce((acc: { [key: string]: number }, investment: GrowProjectInvestment) => {
    if (!acc[investment.status]) {
      acc[investment.status] = 0;
    }

    acc[investment.status] += investment.amount !== null ? investment.amount : 0;
    return acc;
  }, {});

  const groupedInvestmentsArray = Object.entries(groupedInvestments).map(([status, amount]: [GrowProjectStatus, number]) => ({
    status,
    amount
  }));

  return groupedInvestmentsArray.sort((a, b) => {
    const order = [GrowProjectStatus.INTERESTED, GrowProjectStatus.INVESTED, GrowProjectStatus.COMMITTED];
    return order.indexOf(a.status) - order.indexOf(b.status);
  });
};
