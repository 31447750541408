import { RiskQuestionsSurveyOption, PortfolioAllocationItem } from './Strategy';

export interface InyovaGrowAccount {
  id: string;
  currency: string;
  customer_id: number;
  status: InyovaGrowAccountStatus;
  initial_investment: number | null;
  minimum_investment: number;
  monthly_investment: number | null;
  projects_percentage: number | null;
  iban: string | null;
  investment_risk_consent: boolean;
  fees_consent: boolean;
  customer_name: string;
  reporting_timestamp: string;
  portfolio_value: number;
  portfolio_value_chf: number | null;
  portfolio_value_eur: number | null;
  portfolio_value_eur_chf: number | null;
  combined_expected_return: number;
  inyova_and_custody_fees: number;
  product_costs: number;
  edit_risk_profile: boolean;
  eur_exchange_rate: number;
}

export interface GrowProjectAccountSpecific {
  maximum_additional_amount: null | number;
  maximum_regulatory_additional_amount: null | number;
  portfolio_value_in_project_currency: null | number;
  projects_value_in_project_currency: null | number;
  recommended_additional_amount: null | number;
  top_up_amount: null | number;
}

export interface GrowProjectInvestment {
  amount: number | null;
  documents_reviewed_at: string | null;
  general_conditions_accepted_at: string | null;
  risk_agreed_at: string | null;
  status: GrowProjectStatus;
  waiver_agreed_at: string | null;
}

export interface GrowFund {
  annual_report: string;
  asset_class: string;
  custodian_bank: string;
  description: string;
  expected_return: number;
  fact_sheet: string;
  fund_currency: string;
  key_information_document: string;
  manager_document: string;
  prospectus: string;
  status: GrowProjectStatus;
  title: string;
}

export interface GrowProject {
  account_specific: GrowProjectAccountSpecific;
  asset_type: string;
  bonus_payment: number;
  bonus_payment_info: string;
  cash_percentage: number;
  currency: string;
  deadline: string;
  description: string;
  end_date: string;
  expected_return: number;
  fact_sheet: string;
  general_agreements_details: string;
  id: string;
  image: string;
  impact_description: string;
  increment_step: number;
  investment_documents?: { title: string; url: string }[];
  investments: GrowProjectInvestment[];
  inyova_volume: number;
  issuer: string;
  location: string;
  minimum_funding: number;
  opportunities_description: string;
  payout: string;
  risk_description: string;
  sector: string;
  stage: GrowProjectStage;
  term_sheet: string;
  terms_conditions_sheet: string;
  title: string;
  total_volume: number;
  volume_secured_from_customers?: number;
}

export interface GrowProjectsResponse {
  projects: GrowProject[];
  money_market_fund: GrowFund;
}

export interface InyovaGrowRisk {
  type: string;
  id: number;
  risk_survey?: {
    type: string;
    id: number;
    name: string;
    survey_questions: InyovaGrowRiskSurveyQuestion[];
  };
  financial_detail: InyovaGrowFinancialDetail;
  relationshipNames: string[];
}

export interface InyovaGrowRiskSurveyQuestion {
  hint: string;
  id: number;
  inyova_id?: number;
  question_text: string;
  subtype?: string;
  survey_question_options: RiskQuestionsSurveyOption[];
  survey_customer_answers?: null | { id: number; answer_value: number | number[] };
  type: string;
  too_long_options?: boolean;
  hasAnswer?: boolean;
  checkCount?: number;
}

export interface InyovaGrowRiskGroups {
  mandatory: InyovaGrowRiskSurveyQuestion;
  optionalList: InyovaGrowRiskSurveyQuestion;
  optional: InyovaGrowRiskSurveyQuestion[];
  singleChoice: InyovaGrowRiskSurveyQuestion[];
  multiChoices: InyovaGrowRiskSurveyQuestion[];
}

export interface InyovaGrowPercentages {
  calculated_projects_percentage: number;
  id: string;
  maximum_interest: number;
  minimum_interest: number;
  money_market_interest: number;
  recommended_projects_percentage: number;
  type: string;
}

export type InyovaGrowOnboarding = InyovaGrowRisk & { recommended_projects_percentage: InyovaGrowPercentages };

export interface InyovaGrowFinancialDetail {
  freely_cash_available: null | number;
  id: string;
  monthly_income: null | number;
  type: string;
}

// Todo: 29.04.2024 removed selected status
export enum GrowProjectStatus {
  INITIAL = '',
  AUTO = 'auto',
  SELECTED = 'selected',
  INTERESTED = 'interested',
  COMMITTED = 'committed',
  IN_TRANSIT = 'in_transit',
  INVESTED = 'invested',
  REPAID = 'repaid'
}

export enum GrowProjectStage {
  ANNOUNCED = 'announced',
  INVESTABLE = 'investable',
  CANCELLED = 'cancelled',
  CLOSED = 'closed_for_funding',
  RUNNING = 'running',
  ENDED = 'ended',
  DEFAULTED = 'defaulted'
}

export enum InyovaGrowAccountStatus {
  INITIAL = 'initial',
  OPENED = 'opened',
  FUNDED = 'funded',
  CLOSED = 'closed'
}

export interface InyovaGrowSurveyQuestion {
  id: number;
  survey_customer_answers: number | number[];
}

export interface InyovaGrowSubmit {
  interestAccount: {
    id: string;
    type: 'interest_account';
    initial_investment: number;
    monthly_investment: number;
    projects_percentage: number;
    investment_risk_consent: boolean;
    fees_consent: boolean;
  };
  riskSurvey: {
    type: 'risk_survey';
    survey_questions: InyovaGrowSurveyQuestion[];
  };
  financialDetail: {
    id: string;
    type: 'financial_detail';
    monthly_income: number;
    freely_cash_available: number;
  };
}

export type InyovaGrowSubmitResponse = InyovaGrowRisk & { interest_account: InyovaGrowAccount };

export interface GrowPortfolioAllocation {
  id?: string;
  type?: string;
  invested_in_projects: PortfolioAllocationItem;
  committed_to_projects: PortfolioAllocationItem;
  uncommitted_in_mmf: PortfolioAllocationItem;
  uncommitted_cash: PortfolioAllocationItem;
}
