import { Component, Input, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Subject } from 'rxjs';

import { MAXIMUM_3A_CONTRIBUTION } from '@app/app.constants';
import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-home-pillar3a',
  styleUrls: ['./home-pillar3a.component.scss'],
  templateUrl: './home-pillar3a.component.html'
})
export class HomePillar3aComponent implements OnDestroy {
  protected readonly onDestroy$ = new Subject<void>();
  amountSmall = MAXIMUM_3A_CONTRIBUTION[new Date().getFullYear()]['small'];
  amountLarge = MAXIMUM_3A_CONTRIBUTION[new Date().getFullYear()]['large'];

  constructor(
    private modalController: ModalController,
    private trackingService: TrackingService
  ) {}

  close(): void {
    void this.modalController.dismiss({
      dismissed: true
    });
  }

  openDetails(target: string) {
    void this.modalController.dismiss({ next: target });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
